import async from "../components/Async";

// eslint-disable-next-line
import {
  Sliders as SlidersIcon,
  Users as UsersIcon,
  Headphones as HeadphonesIcon,
  TrendingUp as TrendingUpIcon,
  Monitor as MonitorIcon,
  Home as HomeIcon,
} from "react-feather";

// Auth
import SignIn from "../pages/auth/SignIn";
import ResetPassword from "../pages/auth/ResetPassword";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

const Default = async(() => import("../pages/index"));
// Data
const TMCARow = async(() => import("../pages/tables/TMCARow"));

const switchboardTrackingRow = async(() => import("../pages/tables/switchboardTrackingRow"));

const SoundFileRow = async(() => import("../pages/tables/soundFileRow"));

const AgentConversationTime = async(() => import("../pages/tables/agentConversationTimeRow"));

const ZohoPage = async(() => import("../pages/tables/zoho"));

const GrafanaMonitorPage = async(() => import("../pages/monitor/grafanaMonitor"));

const GrafanaAgentsPerfPage = async(() => import("../pages/monitor/grafanaAgentsPerf"));

const GrafanaAgentTMCAPage = async(() => import("../pages/monitor/grafanaAgentTMCA"));

const GrafanaSwitchboardConversionPage = async(() => import("../pages/monitor/grafanaSwitchBoardConversion"));

const GrafanaAgentPage = async(() => import("../pages/monitor/grafanaAgent"));

const GrafanaRingPage = async(() => import("../pages/monitor/grafanaRing"));

const GrafanaAnomalyPage = async(() => import("../pages/monitor/grafanaAnomaly"));

const StatusMonitorPage = async(() => import("../pages/monitor/statusMonitor"));

const defaultRoutes = {
  path: "/",
  realPath: "/",
  name: "Accueil",
  component: Default,
  access: "allAccess",
  icon: HomeIcon
};

const signInRoutes = {
  path: "/auth/sign-in",
  realPath: "/auth/sign-in",
  name: "Authentification",
  component: SignIn,
};

const dashboardRoutes = {
  path: "/callcenter",
  realPath: "/callcenter",
  name: "Centre d'appel",
  badgeColor: "primary",
  icon: HeadphonesIcon,
  containsHome: true,
  access: "staffAccess",
  children: [
    // {
    //   path: "/callcenter/tmcatracking",
    //   realPath: "/callcenter/tmcatracking",
    //   name: "TMCA",
    //   component: TMCARow,
    //   access: "staffAccess"
    // },
    // {
    //   path: "/callcenter/switchboardtracking",
    //   realPath: "/callcenter/switchboardtracking",
    //   name: "Standard",
    //   component: switchboardTrackingRow,
    //   isShow: true,
    //   access: "staffAccess"
    // },
    {
      path: "/callcenter/soundplayer",
      realPath: "/callcenter/soundplayer/:agentName?/:date?/:product?",
      name: "Audios",
      component: SoundFileRow,
      isShow: true,
      access: "staffAccess"
    },
    {
      path: "/callcenter/agentconversationtime",
      realPath: "/callcenter/agentconversationtime/:agentName?/:date?",
      name: "Temps parlé",
      component: AgentConversationTime,
      isShow: true,
      access: "staffAccess"
    },
    {
      path: "https://extranet.mobyquest.com",
      realPath: "https://extranet.mobyquest.com",
      name: "Extranet",
      isShow: true,
      access: "staffAccess",
      isExternal: true,
    },
  ]
};

const monitoringRoutes = {
  path: "/monitor",
  realPath: "/monitor",
  name: "Monitoring",
  badgeColor: "primary",
  icon: MonitorIcon,
  containsHome: true,
  access: "monitorAccess",
  children: [
    // {
    //   path: "/monitor/principal",
    //   realPath: "/monitor/principal",
    //   name: "Principal",
    //   component: GrafanaMonitorPage,
    //   isShow: true,
    //   access: "monitorAccessAdmin"
    // },
    {
      path: "/monitor/status",
      realPath: "/monitor/status",
      name: "Statuts",
      component: StatusMonitorPage,
      isShow: true,
      access: "monitorAccessCallCenter"
    },
    {
      path: "/monitor/agentsPerf",
      realPath: "/monitor/agentsPerf",
      name: "Performance Agents",
      component: GrafanaAgentsPerfPage,
      isShow: true,
      access: "superManagerAccessCallCenter"
    },
    {
      path: "/monitor/agent",
      realPath: "/monitor/agent",
      name: "Agent",
      component: GrafanaAgentPage,
      isShow: true,
      access: "monitorAccessCallCenter"
    },
    {
      path: "/monitor/agentTMCA",
      realPath: "/monitor/agentTMCA",
      name: "TMCA",
      component: GrafanaAgentTMCAPage,
      isShow: true,
      access: "grafanaAccess"
    },
    {
      path: "/monitor/switchboardConversion",
      realPath: "/monitor/switchboardConversion",
      name: "Standard",
      component: GrafanaSwitchboardConversionPage,
      isShow: true,
      access: "grafanaAccess"
    },
    {
      path: "/monitor/ring",
      realPath: "/monitor/ring",
      name: "Temps sonnerie",
      component: GrafanaRingPage,
      isShow: true,
      access: "grafanaAccess"
    },
    {
      path: "/monitor/anomaly",
      realPath: "/monitor/anomaly",
      name: "Anomalies",
      component: GrafanaAnomalyPage,
      isShow: true,
      access: "grafanaAccess"
    },
  ]
};

const traficRoutes = {
  path: "/traffic",
  realPath: "/traffic",
  name: "Trafic",
  badgeColor: "primary",
  icon: TrendingUpIcon,
  containsHome: true,
  access: "externalAccess",
  children: [
    {
      path: "/traffic/zoho",
      realPath: "/traffic/zoho",
      name: "Zoho",
      component: ZohoPage,
      isShow: false,
      access: "externalAccess"
    },
  ]
};


const authRoutes = {
  path: "/auth",
  realPath: "/auth",
  name: "Auth",
  icon: UsersIcon,
  badgeColor: "secondary",
  badgeText: "Special",
  children: [
    {
      path: "/auth/reset-password",
      realPath: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword
    },
    {
      path: "/auth/404",
      realPath: "/auth/404",
      name: "404 Page",
      component: Page404
    },
    {
      path: "/auth/500",
      realPath: "/auth/500",
      name: "500 Page",
      component: Page500
    },
  ]
};

// Dashboard specific routes
export const dashboard = [
defaultRoutes,
dashboardRoutes,
traficRoutes
];

export const unmargeDashboard = [
  monitoringRoutes,
  ];

// Auth specific routes
export const page = [authRoutes];

// SignIn specific routes
export const signin = [signInRoutes]

export const defaultR = [defaultRoutes];

// All routes
export default [
  defaultRoutes,
  monitoringRoutes,
  dashboardRoutes,
  traficRoutes,
];
