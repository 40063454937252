import axios from 'axios';

export default class AuthenticationService {

  static isAuthenticated: boolean = false;
  static isConnected: boolean = false;
  static userName = "";

  static login(login: string, password: string): Promise<boolean> {  
    const userConnection = {
      login: login,
      password: password,
    };
    return new Promise(resolve => {
      axios.post('/RequestConnectionManager.php', {function: "connection", data: userConnection})
      .then(res => {
        console.log(res.data)
        const isAuthenticated = res.data.isOk;
        const userName = res.data.userName;
        this.userName = userName;
        this.isAuthenticated = isAuthenticated;
        JSON.parse(localStorage.setItem("token", res.data.token));
        resolve(isAuthenticated);
      }
      );
    });
  };

  static connected(): Promise<boolean> {  
    return new Promise(resolve => {
      axios.post('/RequestConnectionManager.php', {function: "isConnected"})
      .then(res => {
        const isConnected = res.data;
        this.isConnected = isConnected;
        resolve(isConnected);
      }
      );
    });
  };

  static disconnection(){
    return new Promise(resolve => {
      axios.post('/RequestConnectionManager.php', {function: "disconnection"})
      .then(res => {
        window.location.href = "/";
        resolve(null);
      }
      );
    });
  };

  static tokenExist(){
    if(localStorage.getItem('token')){
      return true;
    }
  }
}

