import React from "react";
import { Link , Switch, Route } from "react-router-dom";
import {connect} from 'react-redux';
import {userLoginFetch} from '../../redux/actions/authenticationAction';
import { createBrowserHistory } from 'history';
import { Redirect } from "react-router";
import store from '../../redux/store/index';


import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
} from "availity-reactstrap-validation";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput
} from "reactstrap";

import {
  faUserCircle,
} from "@fortawesome/free-regular-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class SignIn extends React.Component{

  constructor(props) {
    super(props);

    this.state = {
      login: '',
      password: '',
      error: ''
    }
  }

  handleFormSubmit( event ) {
    const {history} = this.props;
    event.preventDefault();
    if(this.state.login !== "" && this.state.password !== ""){
      this.props.userLoginFetch(this.state).then(() => {
        const storeVal = store.getState();
        if (typeof storeVal.authentication.currentUser !== 'undefined' && typeof storeVal.authentication.currentUser.connected !== 'undefined' && storeVal.authentication.currentUser.connected) {
          history.push("/");
        }
        else{
this.setState({ error: 'Identifiant ou mot de passe invalide.' })
        }
      })
    }

  }
  
  render () {
    return (
        <React.Fragment>
    <div className="text-center mt-4">
      <h2>Bienvenue</h2>
      <p className="lead">Espace de connexion</p>
    </div>

    <Card>
      <CardBody>
        <div className="m-sm-4">
          <div className="text-center">
            <FontAwesomeIcon
                className="align-middle mr-2 fa-5x"
                icon={faUserCircle}
                fixedWidth
              />
          </div>
          <AvForm>
            <AvGroup>
              <Label>Identifiant</Label>
              <AvInput
                bsSize="lg"
                type="login"
                name="login"
                placeholder="Identifiant"
                value={this.state.login}
                onChange={e => this.setState({ login: e.target.value, error: ''})}
                required
              />
              <AvFeedback>Ce champ doit être complété.</AvFeedback>
            </AvGroup>
            <AvGroup>
              <Label>Mot de passe</Label>
              <AvInput
                bsSize="lg"
                type="password"
                name="password"
                placeholder="Mot de passe"
                value={this.state.password}
                onChange={e => this.setState({ password: e.target.value, error: '' })}
                required
              />
              <AvFeedback>Ce champ doit être complété.</AvFeedback>
              <p className="text-danger">{this.state.error}</p>
            </AvGroup>
            <div className="text-center mt-3">
                <Button color="primary" size="lg" onClick={e => this.handleFormSubmit(e)}>
                  Connexion
                </Button>
            </div>
          </AvForm>
        </div>
      </CardBody>
    </Card>
  </React.Fragment>
      )
  }
}

const mapDispatchToProps = dispatch => ({
  userLoginFetch: userInfo => dispatch(userLoginFetch(userInfo))
})

export default connect(null, mapDispatchToProps)(SignIn);