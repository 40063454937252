import * as types from "../constants";
import axios from 'axios';
import { Router, Route } from 'react-router-dom';

export const userLoginFetch = userData => {
  return dispatch => {
    return axios.post('/RequestConnectionManager.php', {function: "connection", data: userData})
    .then(res => {
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("navBarAccess", JSON.stringify(res.data.user.navBarAccess));
      localStorage.setItem("userName", res.data.user.userName);
      dispatch(loginUser(res.data.user))
    }
    );
  }
}


export const getProfileFetch = () => {
  return dispatch => {

    const token = localStorage.getItem("token");
    return axios.post('/RequestConnectionManager.php', {function: "isConnected", data: token})
    .then(res => {
      if(typeof res.data.token !== 'undefined' && res.data.token !== "" && res.data.token !== null){
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("navBarAccess", JSON.stringify(res.data.user.navBarAccess));
        localStorage.setItem("userName", res.data.user.userName);
        dispatch(loginUser(res.data.user))
      }
      else{
        // console.log(res.data)
        dispatch(logoutUser())
      }
    }
    );
  }
}

export const userLogoutFetch = () => {
  localStorage.clear();
  return dispatch => {
    return axios.post('/RequestConnectionManager.php', {function: "disconnection"})
    .then(res => {
      dispatch(logoutUser());
      window.location.replace("/auth/sign-in")
    }
    );
  }
}

export const logoutUser = () => ({
  type: 'LOGOUT_USER'
})

const loginUser = userObj => ({
  type: 'LOGIN_USER',
  payload: userObj
})