import  React, { Component } from 'react';
import { Link } from "react-router-dom";

import { Button } from "reactstrap";

class Page404 extends Component{

  constructor(props) {
    super(props);
  }

    render() {
      return (
      <div className="text-center">
        <h1 className="display-1 font-weight-bold">404</h1>
        <p className="h1">Page introuvable.</p>
        <p className="h2 font-weight-normal mt-3 mb-4">
          La page que vous souhaitez voir n'existe pas ou plus.
        </p>
        <Link to="/">
          <Button color="primary" size="lg">
            Revenir au site
          </Button>
        </Link>
      </div>
    );
  }
}

export default Page404;