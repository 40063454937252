import React from "react";

import Wrapper from "../components/Wrapper";
import Sidebar from "../components/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/Navbar";
import UnmargeContent from "../components/UnmargeContent";
import Footer from "../components/Footer";
import Settings from "../components/Settings";
import Loader from "../components/Loader";

const UnmargeDashboard = ({ children }) => (
  <React.Fragment>
  <div class="loader"><Loader/></div>
    <Wrapper>
      <Sidebar />
      <Main>
        <Navbar />
        <UnmargeContent>{children}</UnmargeContent>
      </Main>
    </Wrapper>
  </React.Fragment>
);

export default UnmargeDashboard;
