import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import {
  dashboard as dashboardRoutes,
  unmargeDashboard as unmargeDashboardRoutes,
  page as pageRoutes,
  signin as signInRoutes,
  defaultR as defaultRoutes,
} from "./index";

import Loader from "../components/Loader";


import {connect} from 'react-redux';
import {getProfileFetch} from '../redux/actions/authenticationAction';

import DashboardLayout from "../layouts/Dashboard";
import UnmargeDashboardLayout from "../layouts/UnmargeDashboard";
import LandingLayout from "../layouts/Auth";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";

import ScrollToTop from "../components/ScrollToTop";

import store from '../redux/store/index';

import axios from 'axios';


function checkIsAuth(){
  let storeVal = store.getState();
  let token = localStorage.getItem('token')
  let navBarAccess = (localStorage.getItem("navBarAccess"));
  if ((storeVal.authentication.currentUser.connected && (typeof token !== 'undefined' && token !== "" && token !== null))) {    
    return true;
  }
  return false;
}

function checkHasAccess(access){
  let navBarAccess = JSON.parse(localStorage.getItem("navBarAccess"));
  if ((navBarAccess !== null && typeof navBarAccess[access] !== 'undefined' && navBarAccess[access])){    
    return true;
  }
  return false;
}



const childRoutes = (Layout, routes, navBarAccess) =>
routes.map(({ children, path, realPath, component: Component, access }, index) =>
  children ? (
      // Route item with children
      children.map(({ path, realPath, component: Component , access}, index) => (
        <Route
        key={index}
        path={realPath}
        exact
        render={props => (
          (checkIsAuth())? 
          checkHasAccess(access) ? 
          <Layout>
          <Component {...props} />
          </Layout> :
          <AuthLayout>
          <Page404 />
          </AuthLayout> :
          <Redirect to={{ pathname: '/auth/sign-in' }} />
          )}
        />
        ))
      ) : (
      // Route item without children
      <Route
      key={index}
      path={realPath}
      exact
      render={props => (
        (checkIsAuth()) ? 
        (checkHasAccess(access)) ? 
        <Layout>
        <Component {...props} />
        </Layout> : 
        <AuthLayout>
        <Page404 />
        </AuthLayout> :
        (path !== "/auth/sign-in") ? 
        <Redirect to={{ pathname: '/auth/sign-in' }} /> : 
        <Layout>
        <Component {...props}  />
        </Layout>
        )}
        />
        )
      )

      class App extends React.Component {
        constructor(props) {
          super();

          this.state = {
            loading: true,
          };

        }

        componentDidMount() {
          this.setState({ loading: 'true' });
          this.props.getProfileFetch().then((data) => {
            this.setState({
              loading: false
            });
          });
        }

        render() {
          if (this.state.loading) {
            return (<Loader/>)
          }

          if (!this.state.loading) {
            return (
              <Router>
              <ScrollToTop>
              <Switch>
              {childRoutes(DashboardLayout, dashboardRoutes)}
              {childRoutes(UnmargeDashboardLayout, unmargeDashboardRoutes)}
              {childRoutes(DashboardLayout, defaultRoutes)}
              {childRoutes(AuthLayout, signInRoutes)}
              <Route
                render={() => (
                  <AuthLayout>
                    <Page404 />
                  </AuthLayout>
                )}
              />
              </Switch>
              </ScrollToTop>
              </Router>
              );
            }
          }
        }

        const mapDispatchToProps = dispatch => ({
          getProfileFetch: userInfo => dispatch(getProfileFetch())
        })


        export default connect(null, mapDispatchToProps)(App);
